import React, { useState } from 'react';
import ReactGA from 'react-ga';
import {
  NavConatiner,
  NavLink,
  MainNav,
  MobileNav,
  BookNowBtn,
  MobileLinksContainer,
  SocialIcon,
  ExternalLink,
} from './styles';
import MenuBurger from '../MenuBurger';
import fbIcon from './assets/fb.svg';
import igIcon from './assets/ig.svg';
import { BOOKING_URL } from '../../constants';

const NavBar = () => {
  const [expand, setToggle] = useState(false);

  const handleExpand = (expand) => {
    setToggle(expand);
  };

  const handleMenuClose = (e) => {
    console.log('target', e.target);
    if (e.target && e.target.matches('a')) {
      setToggle(false);
    }
  };

  const handleBuyNowClick = () => {
    ReactGA.event({
      category: 'User',
      action: 'Book Now Clicked',
      label: 'Book Now',
    });
    console.log('cliccked');
  };

  return (
    <NavConatiner width={[1, 1 / 2, 1 / 4]}>
      <BookNowBtn>
        <ReactGA.OutboundLink eventLabel="Buy Now" to={BOOKING_URL}>
          <ExternalLink bold href={BOOKING_URL}>
            Book Now
          </ExternalLink>
        </ReactGA.OutboundLink>
      </BookNowBtn>
      <MobileLinksContainer>
        <a href="https://www.facebook.com/Blush-Nail-Bar-2738822946160345/" target="_blank" rel="noopener noreferrer">
          <SocialIcon src={fbIcon} alt="facebook" />
        </a>
        <a href="https://www.instagram.com/blushnailbarlondon/" target="_blank" rel="noopener noreferrer">
          <SocialIcon src={igIcon} alt="facebook" />
        </a>
      </MobileLinksContainer>

      <MenuBurger toggleExpand={handleExpand} expand={expand} />
      <MainNav>
        <li>
          <NavLink to="/">Home</NavLink>
        </li>
        {/* <li>
          <NavLink to="/pricing">Pricing</NavLink>
        </li> */}
        {/* <li>
          <NavLink to="/covid-19">COVID-19 Measures</NavLink>
        </li> */}
        <li>
          <NavLink to="/lookbook">Look book</NavLink>
        </li>
        <li>
          <NavLink to="/about">About</NavLink>
        </li>
        <li>
          <NavLink to="/contact">Contact</NavLink>
        </li>
        <li>
          <NavLink to="/package">Party Packages</NavLink>
        </li>
        <li>
          <NavLink to="/gift-card">Gift Card</NavLink>
        </li>
        <li>
          <ReactGA.OutboundLink eventLabel="Buy Now" to={BOOKING_URL}>
            <ExternalLink onClick={handleBuyNowClick} bold href={BOOKING_URL}>
              Book Now
            </ExternalLink>
          </ReactGA.OutboundLink>
        </li>
        <li>
          <a href="https://www.facebook.com/Blush-Nail-Bar-2738822946160345/" target="_blank" rel="noopener noreferrer">
            <SocialIcon src={fbIcon} alt="facebook" />
          </a>
          <a href="https://www.instagram.com/blushnailbarlondon/" target="_blank" rel="noopener noreferrer">
            <SocialIcon src={igIcon} alt="facebook" />
          </a>
        </li>
      </MainNav>
      <MobileNav onClick={handleMenuClose} expand={expand}>
        <li>
          <NavLink to="/">Home</NavLink>
        </li>
        {/* <li>
          <NavLink to="/pricing">Pricing</NavLink>
        </li> */}
        {/* <li>
          <NavLink to="/covid-19">COVID-19 Measures</NavLink>
        </li> */}
        <li>
          <NavLink to="/lookbook">LookBook</NavLink>
        </li>
        <li>
          <NavLink to="/about">About</NavLink>
        </li>
        <li>
          <NavLink to="/contact">Contact</NavLink>
        </li>
        <li>
          <NavLink to="/package">Party Packages</NavLink>
        </li>
        <li>
          <NavLink to="/gift-card">Gift Card</NavLink>
        </li>
      </MobileNav>
    </NavConatiner>
  );
};

export default NavBar;
