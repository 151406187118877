import React from 'react';

import { BurgerContainer, Burger } from './styles';

const MenuBurger = ({ toggleExpand, expand }) => {
  const handleClick = () => {
    toggleExpand(!expand);
  };

  return (
    <BurgerContainer onClick={handleClick}>
      <Burger expand={expand}>
        <div />
        <div />
        <div />
      </Burger>
    </BurgerContainer>
  );
};

export default MenuBurger;
