import styled from 'styled-components';
export const AppContainer = styled.div`
  position: relative;
  padding-bottom: 0; /*260px;*/ /*470px*/
  height: 100%;
  @media (min-width: 769px) {
    padding-bottom: 0; /*300px;*/
  }
`;
export const ComingSoon = styled.div`
  position: relative;
  top: calc(30vh - ${({ theme }) => theme.navHeight}px);
  text-align: center;
  margin: auto;
  color: ${(props) => props.theme.color.ash};
  font-weight: ${(props) => props.theme.fontWeight.thin};
  font-size: ${(props) => props.theme.fontSize.xl}px;
  height: 50vh;

  @media (min-width: 769px) {
    font-size: ${(props) => props.theme.fontSize.xxl}px;
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  margin: 24px auto 0;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  max-width: ${({ theme }) => theme.breakpoints.md}px;
  padding: 0 ${({ theme }) => theme.space[2]}px;
`;

export const Body = styled.div`
  position: relative;
  font-size: ${({ theme }) => theme.fontSize.lg}px;
  margin-bottom: ${({ theme }) => theme.space[2]}px;
`;

export const Title = styled.div`
  position: relative;
  font-size: ${({ theme }) => theme.fontSize.xxl}px;
  margin-bottom: ${({ theme }) => theme.space[2]}px;
`;

export const Image = styled.img`
  display: block;
  width: 100%;
`;
