import { Container } from '../../App.styles';
import styled from 'styled-components';

export const Covid19Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
`;

export const Covid19Body = styled(Container)`
  position: relative;
  flex-direction: column;
  padding: ${({ theme }) => theme.space[2]}px;
  border-radius: 20px;
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-areas: 'a';
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
`;

export const Video = styled.video`
  width: 100%;
  grid-area: a;
`;

export const PlayButton = styled.button`
  position: relative;
  width: 100%;
  height: calc(100% - 50px);
  outline: none;
  cursor: pointer;
  justify-self: center;
  background-color: rgba(0, 0, 0, 0.7);
  grid-area: a;
  border: none;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  svg {
    width: 100%;
  }
`;
