import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NavConatiner = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  top: 0;
  z-index: 98;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.color.blush};
  color: ${(props) => props.theme.color.white};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  min-height: 50px;
  height: auto;
  ul,
  li {
    padding: 0;
    list-style: none;
  }

  ul {
    align-items: center;
  }
`;

export const MainNav = styled.ul`
  position: relative;
  margin: 0;
  top: 0;
  width: 100%;
  justify-content: space-around;
  font-size: ${(props) => props.theme.fontSize.sm}px;
  max-width: ${({ theme }) => theme.breakpoints.md}px;
  display: none;

  li {
    display: flex;
    a {
      text-decoration: none;
    }
  }

  @media screen and (min-width: 900px) {
    display: flex;
  }
`;

export const MobileNav = styled.ul`
  position: absolute;
  top: 0;
  background-color: ${(props) => props.theme.color.blush};
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.theme.fontSize.xl}px;
  max-height: ${(props) => (props.expand ? '1000px' : '0px')};
  margin-top: ${({ theme }) => theme.navHeight - 1}px;
  overflow: hidden;
  width: 100%;
  align-items: center;
  text-align: center;

  li {
    width: 100%;
    padding: ${(props) => props.theme.space[2]}px 0;

    a {
      display: block;
      width: 100%;
      margin: 0 10px;
    }
  }

  transition: max-height 0.5s ease;

  @media (min-width: 900px) {
    display: none;
  }
`;

export const NavLink = styled(Link)`
  color: ${(props) => props.theme.color.ash};
  text-decoration: none;
  text-transform: uppercase;
  padding: 0;
  ${({ theme, bold }) =>
    bold
      ? `
    font-weight: 900;
    color: ${theme.color.white};
    padding: 10px;
    background-color: ${theme.color.ash};
    border-radius: 5px;
  `
      : ''}/* font-size: 16px;

  @media (min-width: 900px) {
    font-size: 14px;
  }*/
`;

export const ExternalLink = styled.a`
  color: ${(props) => props.theme.color.ash};
  text-decoration: none;
  text-transform: uppercase;
  padding: 0;
  font-size: 14px;
  ${({ theme, bold }) =>
    bold
      ? `
    font-weight: 900;
    color: ${theme.color.white};
    padding: 10px;
    background-color: ${theme.color.ash};
    border-radius: 5px;
  `
      : ''}
`;

export const MobileLinksContainer = styled.div`
  position: absolute;
  right: ${(props) => props.theme.space[8]}px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.ash};
  text-decoration: none;
  text-transform: uppercase;
  padding: 0;
  a {
    color: ${(props) => props.theme.color.ash};
    text-decoration: none;
    text-transform: uppercase;
    padding: 0;
  }
  ${NavLink} {
    margin-right: 8px;
  }

  @media (min-width: 900px) {
    display: none;
  }
`;

export const BookNowBtn = styled.div`
  position: absolute;
  left: ${(props) => props.theme.space[3]}px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.ash};
  text-decoration: none;
  text-transform: uppercase;
  a {
    padding: 10px;
    text-decoration: none;
  }

  @media (min-width: 900px) {
    display: none;
  }
`;

export const SocialIcon = styled.img`
  display: block;
  width: 25px;
  height: 25px;
  margin-right: 8px;
`;

export const LightBoxContainer = styled.div`
  position: fixed;
  background-color: #fff;
  top: 0;
  padding-top: 100px;
  left: 0;
  width: 100vw !important;
  bottom: 0;
  overflow-y: auto;
  transition: opacity 0.3s;
  opacity: ${({ showing }) => (showing ? '1 !important' : '0')};
  pointer-events: ${({ showing }) => (showing ? 'auto' : 'none')};
  .hide {
    opacity: 0;
  }
  .gift-overlay-close {
    position: fixed;
    top: 50px;
    font-size: ${({ theme }) => theme.fontSize.xxl}px;
    font-weight: 700;
    cursor: pointer;
    right: ${(props) => props.theme.space[3]}px;
  }
`;
