import React, { useEffect, useState } from 'react';
import { Title } from '../../App.styles';
import { LookBookContainer, LookBookBody, InstaFeedContainer, Loader } from './styles';

const options = {
  accessToken:
    'IGQWRQblNGRHU3ZA0RyNXhiX0RoazFUd19Ja2RwU3I3clRQR0dpTlUtT0tuMzZAJTGdsODIxaDhLZAGJtbTVwY1l4TW1PZAVBvaV9XWjk2SUI0WC1veVNDRGdKMk1hVTZAZALUp4X0tleWpGYXlNZAwZDZD',
  clientId: '616091479010413',
  get: 'user',
  locationId: null,
  resolution: 'standard_resolution',
  sortBy: 'none',
  tagName: null,
  limit: 60,
  userId: 11806848453,
};

const LookBook = () => {
  const [loading, setLoading] = useState(true);
  //const instagramRegExp = new RegExp(/<script type="text\/javascript">window\._sharedData = (.*)<\/script>/);

  useEffect(() => {
    // getInstaFeed();
    //getNewInstaFeed('https://www.instagram.com/blushnailbarlondon/');
    setLoading(true);
    var feed = new window.Instafeed({
      accessToken: options.accessToken,
      template: '<a href="{{link}}" target="_blank"><img title="{{caption}}" src="{{image}}" /></a>',
      limit: 24,
      success: () => {
        setLoading(false);
      },
    });

    feed.run();
  }, []);
  return (
    <LookBookContainer>
      <LookBookBody>
        <Title>Look Book</Title>
        <div id="instafeed"></div>
        {loading && <Loader></Loader>}
        <InstaFeedContainer></InstaFeedContainer>
      </LookBookBody>
    </LookBookContainer>
  );
};

export default LookBook;

/*
<InstaFeedContainer>
          {!loading &&
            data.map(({ caption, id, images, link }, index) => {
              const image = images[options.resolution];
              return (
                <InstaImageContainer key={id} href={link} target="_blank">
                  <InstaImage src={image.url} title={caption.text} caption={caption.text} />
                </InstaImageContainer>
              );
            })}
        </InstaFeedContainer>
        */

/*
         {!loading &&
            data.map(({ caption, id, displayUrl, thumbnailUrl, url }, index) => {
              //const image = images[options.resolution];
              return (
                <InstaImageContainer key={id} href={url} target="_blank">
                  <InstaImage src={thumbnailUrl} title={caption.text} caption={caption.text} />
                </InstaImageContainer>
              );
            })}
            */
