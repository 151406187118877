import styled from 'styled-components';
import { Container } from '../../App.styles';
export const ScriptHolder = styled.div`
  position: relative;
`;
export const GiftCardContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const GiftCardBody = styled(Container)`
  position: relative;
  flex-direction: column;
  padding: ${({ theme }) => theme.space[2]}px;
`;
