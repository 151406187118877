import styled, { keyframes } from 'styled-components';
import { Container } from '../../App.styles';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
const fade = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const LookBookContainer = styled.div`
  position: relative;
  width: 100%;

  #instafeed {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    max-width: 64rem;
    margin: auto;
  }

  #instafeed a {
    position: relative;
    display: block;
    width: 100%;
    &:before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }

  #instafeed img {
    position: absolute;
    top: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: ${fade} 0.5s linear forwards;
  }
`;

export const LookBookBody = styled(Container)`
  position: relative;
  flex-direction: column;
  padding: ${({ theme }) => theme.space[2]}px;
`;

export const LookBookTitle = styled.div`
  position: relative;
  font-size: ${({ theme }) => theme.fontSize.lg}px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 20px;
  padding: 4px 0;
  &:hover {
    background-color: ${({ theme }) => theme.color.blush70};
  }
  transition: background-color 0.5s;
`;

export const InstaFeedContainer = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

export const InstaImageContainer = styled.a`
  position: relative;
  display: block;
  width: 100%;
  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

export const InstaImage = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Loader = styled.div`
  position: fixed;
  border: 5px solid ${({ theme }) => theme.color.blush};
  animation: ${spin} 1s linear infinite;
  border-top: 5px solid #555;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  top: -150px;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
`;
