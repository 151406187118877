import styled from 'styled-components';
import { Container, Body } from '../../App.styles';

export const ContactContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ContactBody = styled(Container)`
  position: relative;
  flex-direction: column;
  padding: ${({ theme }) => theme.space[2]}px;
  border-radius: 20px;
  justify-content: center;
  a {
    color: inherit;
  }
  iframe {
    width: 100%;
  }
`;

export const ContactImageContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 56.25%;
  overflow: hidden;
`;

export const Info = styled(Body)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 769px) {
    flex-direction: row;
  }
`;

export const Address = styled.div`
  position: relative;
  max-width: 300px;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.space[2]}px;
  p {
    font-weight: 700;
    margin: 0;
  }
`;

export const OpeningTimes = styled.div`
  position: relative;
  max-width: 320px;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.space[2]}px;
  p {
    font-weight: 700;
    margin: 0;
  }
  > div {
    display: flex;
    justify-content: space-between;
    flex-basis: 100%;
  }
`;
