import React, { useRef } from 'react';
import { Pannellum } from 'pannellum-react';
import { HomeContainer, Header, Panoramic, HeaderImage, HeaderImageContainer } from './styles';
import panoramicImg from './assets/panoramic.jpg';
import logo1 from './assets/logo-1.png';
import logo2 from './assets/logo-2.png';

const Home = () => {
  const pan = useRef(null);
  const handleCustom = e => {
    e.preventDefault();
  };

  const handleLeave = () => {
    pan.current.getViewer().startAutoRotate(-5);
  };

  return (
    <HomeContainer>
      <Header>
        <HeaderImageContainer>
          <picture>
            <source media="(min-width: 769px)" srcSet={logo1} />
            <HeaderImage src={logo2} alt="logo" />
          </picture>
        </HeaderImageContainer>
      </Header>
      <Panoramic onContextMenu={handleCustom} onMouseUp={handleLeave} onTouchEnd={handleLeave}>
        <Pannellum
          ref={pan}
          image={panoramicImg}
          width="100%"
          height="100%"
          maxHfov={100}
          minHfov={100}
          autoLoad
          autoRotate={-5}
          showControls={false}
        />
      </Panoramic>
    </HomeContainer>
  );
};

export default Home;
