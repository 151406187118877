import React, { useEffect } from 'react';
import { GiftCardContainer, GiftCardBody } from './styles';
import { Title } from '../../App.styles';

const GiftCard = () => {
  useEffect(() => {
    ((g, i, f, t, u, p) => {
      i = g.createElement('script');
      f = g.getElementsByTagName('script')[0];
      i.async = 1;
      i.src = 'https://cdn.giftup.app/dist/gift-up.js';
      f.parentNode.insertBefore(i, f);
    })(document);
  }, []);
  return (
    <GiftCardContainer>
      <GiftCardBody>
        <Title>Gift card</Title>

        <div className="gift-up-target" data-site-id="5b4be7c6-934b-4ee7-85fa-ca0c99c90fbc" data-platform="Other"></div>
      </GiftCardBody>
    </GiftCardContainer>
  );
};

export default GiftCard;
