import styled from 'styled-components';
import { Container, Body } from '../../App.styles';

export const AboutContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const AboutBody = styled(Container)`
  position: relative;
  flex-direction: column;
  padding: ${({ theme }) => theme.space[2]}px;
  border-radius: 20px;
  ${Body} {
    line-height: 1.8;
    font-weight: ${({ theme }) => theme.fontWeight.light};
  }
`;

export const AboutIcons = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @media (min-width: 769px) {
    flex-direction: row;
  }
`;

export const AboutIcon = styled.div`
  position: relative;
  display: flex;
  width: 300px;
  align-items: center;
  flex-direction: column;
  font-size: ${({ theme }) => theme.fontSize.lg}px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-transform: uppercase;
  justify-content: space-between;
  color: ${({ theme }) => theme.color.black};
  padding: 0 ${({ theme }) => theme.space[2]}px;
  @media (min-width: 769px) {
    width: 350px;
    padding: 0;
    font-size: ${({ theme }) => theme.fontSize.xl}px;
  }
`;

export const AboutImageContainer = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.blush};
  border-radius: 250px;
  margin: ${({ theme }) => theme.space[5]}px 0;
  padding: ${({ theme }) => theme.space[4]}px;

  @media (min-width: 769px) {
    width: 250px;
    height: 250px;
  }
`;
