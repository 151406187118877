import React from 'react';
import { Title, Body } from '../../App.styles';
import { ContactContainer, ContactBody, ContactImageContainer, Address, OpeningTimes, Info } from './styles';

const Contact = () => {
  return (
    <ContactContainer>
      <ContactBody>
        <Title>Contact us</Title>
        <Body>
          <Info>
            <Address>
              <Body>
                <p>Blush Nail Bar</p>
              </Body>
              71 Mill Hill, <br />
              The Broadway <br />
              NW7 3BU <br />
              Telephone: <a href="tel:+44-020-8906-3196">+44 020 8906 3196</a>
              <br />
              Email: <a href="mailto:info@blushnailbar.co.uk">info@blushnailbar.co.uk</a>
            </Address>
            <OpeningTimes>
              <Body>
                <p>Opening times:</p>
              </Body>
              <div>
                <span>Monday</span>
                <span>10:00 am–7:00 pm</span>
              </div>
              <div>
                <span>Tuesday</span>
                <span>10:00 am–7:00 pm</span>
              </div>
              <div>
                <span>Wednesday</span>
                <span>10:00 am–7:00 pm</span>
              </div>
              <div>
                <span>Thursday</span>
                <span>10:00 am–7:00 pm</span>
              </div>
              <div>
                <span>Friday</span>
                <span>10:00 am–7:00 pm</span>
              </div>
              <div>
                <span>Saturday</span>
                <span>10:00 am–7:00 pm</span>
              </div>
              <div>
                <span>Sunday</span>
                <span>10:00 am–5:00 pm</span>
              </div>
            </OpeningTimes>
          </Info>

          <ContactImageContainer>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2477.4446106988594!2d-0.2485951837221727!3d51.615064210645784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487616dca20509bd%3A0xbdc7a935b56dc6c6!2s71+The+Broadway%2C+Mill+Hill%2C+London+NW7+3BU!5e0!3m2!1sen!2suk!4v1554928578231!5m2!1sen!2suk"
              title="Blush Nail Bar"
              width="800"
              height="600"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen
            />
          </ContactImageContainer>
        </Body>
      </ContactBody>
    </ContactContainer>
  );
};

export default Contact;
