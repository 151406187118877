export const mainTheme = {
  color: {
    white: '#ffffff',
    black: '#000000',
    blush: '#f8eaea',
    blush70: 'rgba(248, 234, 234, 0.7)',
    gold: '#FFD700',
    ash: '#5a5c5b',
    footer: '#f1f1f1',
    red: '#ff0000'
  },
  space: [0, 6, 12, 18, 24, 30, 36, 42, 48, 54],
  fontSizes: [10, 12, 14, 16, 24, 32, 48, 64, 72],
  fontSize: { xs: 12, sm: 14, md: 16, lg: 18, xl: 24, xxl: 48 },
  fontFamily: { helvetica: 'HelveticaLTStd', roboto: 'Roboto', raleway: 'Raleway' },
  fontWeight: { thin: 100, light: 300, normal: 400, bold: 500 },
  breakpoints: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 },
  navHeight: 51
};
