import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
const history = createBrowserHistory();

history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
  window.scrollTo(0, 0);
});

export default history;
