import React from 'react';
import { AboutContainer, AboutBody, AboutImageContainer, AboutIcons, AboutIcon } from './styles';
import { Title, Body, Image } from '../../App.styles';
import nail from './assets/nail-icon.svg';
import coffee from './assets/coffee.svg';
import champagne from './assets/champagne.svg';

const About = () => {
  return (
    <AboutContainer>
      <AboutBody>
        <Title>About us</Title>
        <Body>
          Blush is a trendy nail salon founded on the principles of affordable luxury with excellent customer service
          being our ultimate priority. At Blush we pride ourselves on using a range of high quality products, including
          cruelty-free and vegan brands to deliver an exceptional experience.
          <br />
          <br />
          We are all about a nail experience that is second to none. To achieve this, we make you feel special every
          time you come in for a visit - from the ‘welcome’ received at the door right up to the 'see you again' after
          your treatment.
          <br />
          <br />
          We look forward to welcoming you soon and one of our qualified and experienced nail technicians will set up a
          free nail profile for you, for stress-free ongoing nail maintenance.
          <br />
          <br />
          Come in, have a pamper session, a cup of coffee, or even a glass of bubbly on us!
        </Body>
        <AboutIcons>
          <AboutIcon>
            <AboutImageContainer>
              <Image src={nail} alt="nail" />
            </AboutImageContainer>
            Nails
          </AboutIcon>
          <AboutIcon>
            <AboutImageContainer>
              <Image src={coffee} alt="coffee" />
            </AboutImageContainer>
            Coffee
          </AboutIcon>
          <AboutIcon>
            <AboutImageContainer>
              <Image src={champagne} alt="champagne" />
            </AboutImageContainer>
            Bubbly
          </AboutIcon>
        </AboutIcons>
      </AboutBody>
    </AboutContainer>
  );
};

export default About;
