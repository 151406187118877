import { createGlobalStyle } from 'styled-components';
import marbleBg from './marble.jpg';

export const GlobalStyle = createGlobalStyle`
  *{
    box-sizing: border-box;
  }

  html,
  body {
    background-color: ${props => props.theme.color.white};   
    background-image: url(${marbleBg});
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment :fixed;
    width: 100%;
    color: ${({ theme }) => theme.color.ash};
    -webkit-overflow-scrolling: touch;
  }
  html {
    overflow: auto;
    height: 100%; 
  }
    body {
      margin: 0;
      height: 100%;
      padding-top: ${({ theme }) => theme.navHeight}px;
      font-family: ${props =>
        props.theme.fontFamily.raleway};/*-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;*/
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    #root {
      position: relative;
      width: 100%;
      min-height: calc(100vh - ${({ theme }) => theme.navHeight}px);
    }


    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
    }

    .fade-enter {
      opacity: 0;
    }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 400ms ease;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-active {
    opacity: 0;
    transition: opacity 400ms ease;
  }
`;
