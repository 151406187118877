import styled from 'styled-components';

export const BurgerContainer = styled.div`
  position: absolute;
  right: ${props => props.theme.space[3]}px;
  top: 12px;
  z-index: 999;

  @media (min-width: 900px) {
    display: none;
  }
`;

export const Burger = styled.div`
  div {
    width: 30px;
    height: 4px;
    background-color: ${props => props.theme.color.ash};
    margin: 4px 0;
    opacity: ${props => (props.expand ? '0' : '1')};
    transition: transform 0.3s ease;

    &:first-child {
      margin-bottom: 0;
      opacity: 1;
      transform: ${props => (props.expand ? 'rotate(-45deg) translate(-9px, 6px)' : 'none')};
    }

    &:last-child {
      margin-bottom: 0;
      opacity: 1;
      transform: ${props => (props.expand ? ' rotate(45deg) translate(-4px, -4px)' : 'none')};
    }
  }
`;
