import styled from 'styled-components';
import { Container } from '../../App.styles';
export const PackageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const PackageBody = styled(Container)`
  position: relative;
  flex-direction: column;
  padding: ${({ theme }) => theme.space[2]}px;
`;

export const PackageInfo = styled.div`
  position: relative;
  font-weight: 300;
  font-size: ${({ theme }) => theme.fontSize.lg}px;
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 20px;
  }
`;
