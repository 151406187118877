import styled, { keyframes } from 'styled-components';

const fade = keyframes`
  0%   { opacity: 1; }
  50%  { opacity: 0.3; }
  100% { opacity: 1; }
`;

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  color: ${props => props.theme.color.blush};
  font-size: ${props => props.theme.fontSize.xl}px;
  font-weight: ${props => props.theme.fontWeight.thin};
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  padding: ${props => props.theme.space[5]}px 0;

  @media (min-width: 769px) {
    font-size: ${props => props.theme.fontSize.xxl}px;
  }
`;

export const HeaderImageContainer = styled.div`
  flex-basis: 50%;
  height: auto;

  @media (min-width: 769px) {
    flex-basis: 60%;
  }
`;

export const HeaderImage = styled.img`
  max-width: 100%;
  height: auto;

  @media (min-width: 769px) {
  }
`;

export const Panoramic = styled.div`
  position: relative;
  height: 200px;

  .pnlm-about-msg {
    padding: 0;
    a {
      display: none;
    }
  }
  @media (min-width: 480px) {
    height: 300px;
  }

  @media (min-width: 769px) {
    height: 500px;
  }
`;

export const ComingSoonImage = styled.div`
  position: relative;
  flex-basis: 50%;

  @media (min-width: 769px) {
    flex-basis: 40%;
  }
  img {
    max-width: 100%;
    height: auto;
  }

  animation: ${fade} 2s ease-in-out infinite alternate;
`;
