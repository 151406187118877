import React from 'react';
import { PackageContainer, PackageBody, PackageInfo } from './styles';
import EnquiryForm from '../EnquiryForm';
import { Title } from '../../App.styles';

const Package = () => {
  return (
    <PackageContainer>
      <PackageBody>
        <Title>Party Packages</Title>
        <PackageInfo>
          At Blush we cater to hen parties, private events even kids parties where a group can come to the store to have
          an uninterrupted pamper session.{' '}
        </PackageInfo>
        <PackageInfo>
          To find out more information about available packages, please enter your details below and we will get back to
          you.
        </PackageInfo>
        <PackageInfo>
          To make a same-day enquiry please call the store directly and we will be happy to book your group in.
        </PackageInfo>
        <EnquiryForm title="Enquiry Form:" />
      </PackageBody>
    </PackageContainer>
  );
};

export default Package;
