import styled from 'styled-components';

export const Form = styled.form`
  padding: 0;
  margin: 0 auto;
  position: relative;
  width: 100%;
  max-width: 300px;
  font-size: ${({ theme }) => theme.fontSize.xs}px;
  span {
    color: ${({ theme }) => theme.color.red};
  }

  @media (min-width: 769px) {
    margin: 0;
  }
`;

export const FormTitle = styled.div`
  position: relative;
  font-size: ${({ theme }) => theme.fontSize.lg}px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: ${({ theme }) => theme.space[2]}px;
`;

export const Input = styled.input`
  padding: 0;
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.color.ash};
  &[type='date']:before {
    content: attr(placeholder) !important;
    margin-right: 0.5em;
  }
  &[type='date']:focus:before,
  &[type='date']:valid:before {
    content: '';
  }
`;

export const ServiceItems = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 5px;
  max-height: 300px;
  overflow: auto;
`;

export const CheckboxContainer = styled.label`
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 5px;
`;

export const StatusContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  pointer-events: ${({ active }) => (active ? 'auto' : 'none')};
  opacity: ${({ active }) => (active ? '1' : '0')};
  transition: opacity 0.5s;
`;

export const Status = styled.div`
  background-color: ${({ theme }) => theme.color.blush};
  padding: 40px;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.fontSize.lg}px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  padding: 10px;
  color: ${({ theme }) => theme.color.ash};
  display: inline;
`;

export const Message = styled.textarea`
  width: 100%;
  height: 150px;
  padding: 10px;
  margin-bottom: 5px;
`;

export const Button = styled.input.attrs({ type: 'submit' })`
  padding: 0;
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
  background-color: ${({ theme }) => theme.color.blush};
  pointer-events: ${({ isSubmitting }) => (isSubmitting ? 'none' : 'auto')};
  opacity: ${({ isSubmitting }) => (isSubmitting ? '0.5' : '1')};
`;
