import React, { Fragment } from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Home from './components/Home';
import LookBook from './components/LookBook';
import About from './components/About';
import Contact from './components/Contact';
import NotFound from './components/NotFound';
import NavBar from './components/NavBar';
import { GlobalStyle } from './index.styles';
import { mainTheme } from './theme';
import history from './history';
import GiftCard from './components/GiftCard';
import { AppContainer } from './App.styles';
import Package from './components/Package';
import Covid19 from './components/Covid19';
import { BOOKING_URL } from './constants';

const App = () => {
  return (
    <Router history={history}>
      <ThemeProvider theme={mainTheme}>
        <Fragment>
          <GlobalStyle />
          <NavBar />
          <AppContainer>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/lookbook" component={LookBook} />
              {/* <Route path="/covid-19" component={Covid19} /> */}
              <Route path="/about" component={About} />
              <Route path="/contact" component={Contact} />
              <Route path="/gift-card" component={GiftCard} />
              <Route
                path="/book"
                component={() => {
                  window.location.href = BOOKING_URL;
                  return null;
                }}
              />
              <Route path="/package" component={Package} />
              <Route component={NotFound} />
            </Switch>
          </AppContainer>
        </Fragment>
      </ThemeProvider>
    </Router>
  );
};

export default App;
