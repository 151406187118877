import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Form, Input, Button, FormTitle, Message, StatusContainer, Status } from './styles';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const enquiryValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(40, 'Please enter no more than 40 characters')
    .required('Please enter your first name'),
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Please enter an email'),
  phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  guests: Yup.number('Please enter a whole number')
    .positive('Please enter a whole number')
    .integer('Please enter a whole number')
});

const initialValues = { name: '', email: '', phone: '', guests: '', date: '', services: [], comments: '' };

const EnquiryForm = ({ title }) => {
  const [status, setStatus] = useState({ error: false, message: '' });
  const hideStatus = () => {
    setStatus({ message: '', error: false });
  };
  const handleFormSubmit = async (values, actions) => {
    try {
      const response = await fetch('https://blushnailbar.co.uk/enquiry.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json'
        },
        mode: 'cors',
        credentials: 'same-origin',
        body: JSON.stringify(values)
      });
      const data = await response.json();
      if (data.success) {
        setStatus({ message: 'Thank you, we will be in touch soon', error: false });
        actions.resetForm(initialValues);
      } else {
        setStatus({ message: 'Thank you, we will be in touch soon', error: true });
      }
    } catch (err) {
      setStatus({ message: 'An Error Ocurred', error: true });
    }

    window.setTimeout(hideStatus, 2500);
  };
  const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = useFormik({
    onSubmit: handleFormSubmit,
    validateOnChange: true,
    validationSchema: enquiryValidationSchema,
    initialValues
  });

  return (
    <Form onSubmit={handleSubmit}>
      <FormTitle>{title}</FormTitle>
      <Input
        type="text"
        name="name"
        placeholder={'Name'}
        onChange={handleChange}
        onBlur={handleBlur}
        required
        value={values.name || ''}
      />
      <span>{errors.name && touched.name && errors.name}</span>
      <Input
        type="text"
        name="email"
        placeholder={'Email'}
        onChange={handleChange}
        onBlur={handleBlur}
        required
        value={values.email}
      />
      <span>{errors.email && touched.email && errors.email}</span>
      <Input
        type="text"
        name="phone"
        placeholder={'Telephone'}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.phone}
      />
      <span>{errors.phone && touched.phone && errors.phone}</span>
      <Input
        type="date"
        name="date"
        placeholder={'Date'}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.date}
      />
      <Input
        type="text"
        name="guests"
        placeholder={'No of Guests'}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.guests}
      />
      <span>{errors.guests && touched.guests && errors.guests}</span>
      <Message
        type="text"
        name="comments"
        placeholder={'Leave a message'}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.comments}
      />
      <span>{errors.guests && touched.guests}</span>
      <Button isSubmitting={isSubmitting} value="Submit" />
      <StatusContainer active={status.message}>
        <Status>{status.message}</Status>
      </StatusContainer>
    </Form>
  );
};

EnquiryForm.propTypes = {
  title: PropTypes.string
};

EnquiryForm.defaultProps = {
  title: 'Enquiry form'
};
export default EnquiryForm;
